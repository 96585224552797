@tailwind base;
@tailwind components;
@tailwind utilities;

.btn {
  background: linear-gradient(92.23deg,
      #ff56f6 21.43%,
      #b936ee 50.63%,
      #3bace2 100%,
      #406aff 117.04%);
  /* box-shadow: 0px 4.42184px 107.23px rgba(255, 86, 246, 0.51); */
}

.btn:hover {
  background: linear-gradient(92.23deg,
      #406aff 21.43%,
      #3bace2 50.63%,
      #b936ee 100%,
      #ff56f6 117.04%);
}

.gradient,
.active {
  background: linear-gradient(92.23deg,
      #406aff 21.43%,
      #3bace2 50.63%,
      #b936ee 100%,
      #ff56f6 117.04%);
}

.active {
  color: #fff;
  padding: 1rem;
  border-radius: 100%;
}

.nav-btn {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

@layer base {
  body {
    @apply font-secondary text-lg leading-8 text-white;
  }

  .h2 {
    @apply font-primary text-[32px] mb-6 tracking-[10%] uppercase;
  }

  .h3 {
    @apply font-primary font-semibold text-[26px] mb-6 leading-[46px];
  }

  .btn {
    @apply rounded-full font-primary text-white font-medium;
  }

  .btn-sm {
    @apply h-[48px] px-6 text-sm;
  }

  .btn-md {
    @apply h-[50px] px-7 text-base;
  }

  .btn-lg {
    @apply h-[56px] px-10 text-base;
  }

  .btn-link {
    @apply text-base;
  }

  .text-gradient {
    @apply bg-gradient-to-r text-transparent bg-clip-text from-[#42A6E3] to-[#FF56F6] font-bold font-primary hover:from-[#FF56F6] hover:to-[#42A6E3];
  }

  .section {
    @apply py-8 lg:py-24 lg:h-screen flex items-center;
  }
}

/* --------------------------------------------- */

.rainbow-btn {
  text-align: center;
  line-height: 60px;
  color: #fff;
  text-decoration: none;
  font-family: sans-serif;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(90deg,
      violet 00%,
      violet 25%,
      purple 50%,
      purple 85%,
      violet 100%
      );
  background-size: 600% 300%;
  border-radius: 30px;
  z-index: 1;
  animation: animate 10s linear infinite;
  transition: background-position 0.3s ease;
}

.rainbow-btn:hover {
  animation-direction: reverse;
}

.nav-btn.active {
  /* Styles from .rainbow-btn */
  text-align: center;
  line-height: 60px;
  color: #fff;
  text-decoration: none;
  font-family: sans-serif;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(90deg,
      violet 00%,
      violet 30%,
      purple 50%,
      purple 85%,
      violet 100%
      );
  background-size: 600% 300%;
  border-radius: 30px;
  z-index: 1;
  animation: animate 8s linear infinite;
}


@keyframes animate {
  0% {
    background-position: 0% 0%;
  }

  100% {
    background-position: 120% 0%;
  }
}

@keyframes clickEffect {
  0% { transform: scale(1); }
  25% { transform: scale(0.9); }
  50% { transform: scale(1); }
  75% { transform: scale(0.9); }
  100% { transform: scale(1); }
}
